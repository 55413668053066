import React from 'react';
import styles from './index.module.scss';

export default function VideoGrid({ yt = [] }) {
    return (
        <div className={styles.grid}>
            {yt.map(url => (
                <iframe
                    key={url}
                    src={url}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                />
            ))}
        </div>
    );
}
