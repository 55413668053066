import ArrowButton from '../components/arrow-button';
import Layout from '../components/layout';
import VideoGrid from '../components/video-grid';
import React, { useState } from 'react';
import SEO from '../components/seo';

export default function MixesPage() {
    const [darkMode, setDarkMode] = useState(1);
    const [audio, setAudio] = useState(1);
    const sw = {
        setDarkMode,
        setAudio,
        values: { darkMode, audio }
    };
    return (
        <Layout sw={sw}>
            <SEO title="mixes" />
            <ArrowButton variant="back" href="/" fixed>
                Home
            </ArrowButton>
            <VideoGrid
                yt={[
                    'https://www.youtube.com/embed/bpxC-gUnzTk',
                    'https://www.youtube.com/embed/X2EwNPTJbwA',
                    'https://www.youtube.com/embed/Gnw5H_mTwVk',
                    'https://www.youtube.com/embed/vVdD1VjbOLA',
                    'https://www.youtube.com/embed/JpiCDKT5pkQ',
                    'https://www.youtube.com/embed/TreyAVcZmtk'
                ]}
            />
        </Layout>
    );
}
